.seccion-lineup{
	background: url(../img/imagen_hotel.webp);
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	height: 100%;
	color: $blanco;
	
	@include tablet{		
		overflow: hidden;
	}

	.lineup{
		background: rgba(19, 63, 129, 0.637);
		padding: 3rem;		
	}
}

.oferta-contenedor{
	@include tablet{
		padding: 2rem;
		@include grid (2,$separacionDos);
	}
	@include desktop{
		padding: 2rem;
		@include grid (4,$separacionDos);
	}	
}

.oferta{
	text-align: center;
	padding: 1rem;
	margin: $separacionDos auto;
	border-radius: 6px;
	line-height: 1.5;

	.nombre-oferta{
		text-transform: uppercase;
		&:after{
			text-align: center;
			content: '';			
			display: block;
			font-size: 10rem;
			padding: 2rem 0;
		}
	}
}
/* Iconos de ofertas */
.escape .nombre-oferta{
	@include iconos ($fa-var-hotel);
}
.basic .nombre-oferta{
	@include iconos ($fa-var-utensils);
}
.familiar .nombre-oferta{
	@include iconos ($fa-var-child);
}
.full .nombre-oferta{
	@include iconos ($fa-var-wifi);
}

.lista-oferta{
	padding: 0;
	list-style: none;
	text-align: left;
	li{
		padding: $separacionDos / 2;
		font-weight: $regular;
	}
}
.bg-dos .lista-oferta li:nth-child(odd), .bg-tres .lista-oferta li:nth-child(odd){
	/* darken solo existe en sass, no existe en CSS */
	background-color: darken($gris, 10); 
}
.bg-uno .lista-oferta li:nth-child(odd), .bg-cuatro .lista-oferta li:nth-child(odd){
	background-color: darken($principal, 10);
}

@include telEscritorio{
	.bg-uno .lista-oferta li:nth-child(odd), .bg-tres .lista-oferta li:nth-child(odd){
		background-color: darken($principal, 10);
	}
	.bg-dos .lista-oferta li:nth-child(odd), .bg-cuatro .lista-oferta li:nth-child(odd){
		background-color: darken($gris, 10);
	}
}