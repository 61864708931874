.body-instructivos{
	background: url('../../../build/img/fondo-nosotros.svg');
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.info-securapp{
	position: relative;
	background: hsla(208, 33%, 21%, 1);

background: linear-gradient(90deg, hsla(208, 33%, 21%, 1) 0%, hsla(211, 36%, 46%, 1) 100%)!important;

background: -moz-linear-gradient(90deg, hsla(208, 33%, 21%, 1) 0%, hsla(211, 36%, 46%, 1) 100%)!important;

background: -webkit-linear-gradient(90deg, hsla(208, 33%, 21%, 1) 0%, hsla(211, 36%, 46%, 1) 100%)!important;
	background-size:cover;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 77rem!important;
	color: $blanco;
	z-index: 2!important;

	@include tablet{
		height: 60rem!important;
	}
	@include desktop{
		height: 70rem!important;
		margin-top: 0rem!important;
	}
	
}
.img-white-video{
	position: absolute;
	z-index: -1!important;
	width: 100%!important;
	height: 100%!important;
	top: 0;
	left: 0;
}
.btn-securapp{
	background: linear-gradient(310deg,#7928ca, #ff0080)!important;
	z-index: 999!important;
}
.btn-securapp:hover{
	color: rgb(174, 169, 169);
}
.video-contenido{
		background-color: none!important;
		border-radius: 2rem;
		padding-bottom: 3rem!important;
		margin-bottom: 7rem;
		width: 90%!important;
		height: 17rem!important;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items:center;
		@include tablet{
			display: block !important;
			width: 50%!important;
			height: 23rem!important;
		}
		@include desktop{
			height: 37rem!important;
			width: 50%!important;
		}
		@include extralarge{
			margin-top:3%!important;
			width: 50%!important;
			height: 40rem!important;
		}

		
}

.info-prl{
	background: url('../../../build/img/fondo-nosotros.svg') center no-repeat;
	background-size:cover;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 70rem;
	color: $blanco;
	@include tablet{
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
	}
	.info-contenido{
		padding: 2rem;
		text-align: center;
		@include tablet{
			padding: 0;
		}
		h1{		
			font-size: 3rem;
			text-transform: uppercase;
			font-family: $fuenteLogo;
			font-weight: 700;
			@include tablet{
				font-size: 5.5rem;
			}
		}
		h2{
			padding-top: 2rem;
			font-family: $fuenteEnlace;
		}
		p{
			text-align: justify;
			width:90%;
			margin: 0;
			padding: 2rem 0;
			color: $blanco;
			@include tablet{
				text-align: initial;
			}
			
		}
	}
	.foto-contenido{
		display: none !important;
		background-color: #fff;
		border-radius: 2rem;
		height: 68%;
		padding-bottom: 2rem;
		margin-bottom: 7rem;
		width: 26%;
		height: 47rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items:center;
		@include tablet{
			display: block !important;
			width: 32%;
		}
		@include desktop{
			width: 26%;
		}
		img{
			height: 30rem;
			border-radius: 2rem 2rem 0 0;
			@include extralarge{
				height: 35rem;
			}
		}
		
		p{
			line-height:1.5 !important;
			font-size:1.3 !important;
			width:90% !important;
			text-align:justify;
			padding: 1rem;
		}
	}
	
}
.video-contenido {
	background-color: none!important;
	border-radius: 2rem;
	padding-bottom: 3rem!important;
	margin-bottom: 7rem;
	width: 90%!important;
	height: 17rem!important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center
}
.iframe-video{
	margin-top: 4rem;
}
@media (min-width: 700px) {
	.video-contenido {
		 display: block!important;
		 width: 50%!important;
		 height: 23rem!important
	}
	.iframe-video{
		 width:360px;
		 height:200px;
	}
}
@media (min-width: 1000px) {
	.video-contenido {
		 height: 37rem!important;
		 width: 50%!important
	}
	.iframe-video{
		 width:560px;
		 height:315px;
	}
}
@media (min-width: 1500px) {
	.video-contenido {
		 margin-top: 3%!important;
		 width: 50%!important;
		 height: 40rem!important
	}
}