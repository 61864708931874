.instructivos{
	margin-top: 7rem!important;
}
.caption-instructivos{
	text-align: left !important;
	background-image: linear-gradient(to right top, #8e268bc2, #9a1ca3d7, #a20fbfc4, #a701deb9, #a600ff);
	width:100%;
	height: 90%!important;
	.titulo-caption-nosotros{
		font-size: 2.5rem!important;
		@include tablet{
			font-size: 6rem!important;
		}
	}
	.parrafo-caption-nosotros{
		font-size: 1.5rem!important;
	}
	
}
.sedial{
	margin-top:7rem!important;
	.img-sedial{
		width: 240px;
		height: 240px;
		overflow: hidden;
		border-radius: 50%;
		margin: 3rem 17%;
		
		 @include tablet{
			margin: 0 30%;
		 }
	}
	
}

.servimaints{
	background: url('../../../build/img/fondo-serv.svg')!important;
}

.imgsecur{
	width:70%;
}

/* pagina videos instructivos */

.caption-videos-instructivos{
	text-align: left !important;
	background-image: linear-gradient(to right, #b8cbb8e3 0%, #b8cbb8de 0%, #b565dade 0%, #cf6cc8e1 33%, #ee609bd8 66%, #ee609bcc 100%);
	width:100%;
	height: 90%!important;
	.titulo-caption-nosotros{
		font-size: 2.5rem!important;
		@include tablet{
			font-size: 6rem!important;
		}
	}
	.parrafo-caption-nosotros{
		font-size: 1.5rem!important;
	}
}
.instructivos-items{

	@include grid (1,0);

	@include tablet{
		@include grid(3, 0);
	}
	.item-instructivo{
		margin: 1.5rem;
		border-radius: 1.5rem;
		padding: 1rem;
		/* background-color: #00407c; */
		background-image: linear-gradient(to right top, #4d5353, #345259, #114f65, #004973, #00407c);
		height: 38rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		@include tablet{
			padding: 1.5rem;
			margin: 1.5rem;

		}
		.instructivo-img{
			img{
				border-radius: 1.5rem;
				height:23rem!important;
			}
		}
		.instructivo-contenedor{
			h3{
				padding: 1.5rem;
				color: #fff;
				font-family: $fuenteEnlace;
				font-size: 3rem;
				font-weight: 600;
				text-align: center;
				text-transform: uppercase;
			}
			p{
   			text-align: center!important;
   			font-size: 1.5rem!important;
   			width: 95%!important;
				color: #fff!important;
			}
		}
	}
	.item-instructivo-servi{
		background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%)!important;
	}
	.item-instructivo-sla{
		background-image: linear-gradient(to right top, #1e364f, #385371, #547296, #7092bc, #8eb4e3);
	}
}
/* pagina instructivos sedial */
.caption-sedial{
	text-align: left !important;
	background-image: linear-gradient(to top, #30d0d0b4 0%, #330867a8 100%)!important;
	width:100%;
	height: 90%!important;
	.titulo-caption-nosotros{
		font-size: 2.5rem!important;
		@include tablet{
			font-size: 6rem!important;
		}
	}
	.parrafo-caption-nosotros{
		font-size: 1.5rem!important;
	}
}
.sedialins{
	background: url('../../../build/img/fondo-instructivo-servi.svg')!important;
}
.contenido-sedial{
	display: flex!important;
   flex-direction: column!important;
   justify-content: center!important;
}
