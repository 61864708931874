/* Uso de mixins con palabra reservada de SASS @missin */
/* Pueden tener argumentos o parametros como funciones */
/* Si llevan parametros deben ir asi @mixin nuevoMixin () */
@mixin nuevoMixin ($transform, $size){
	font-size: $size;
	color: $blanco;
	text-transform: $transform;
}

@mixin telefono{
	@media (min-width: $telefono){
		/* Para que los mixins soporten varias lineas de codigo */
		@content; /* Directiva de SASS */
	}
}
@mixin tablet{
	@media (min-width: $tablet){
		@content; 
	}
}
@mixin tablet2{
	@media (min-width: $tablet2){
		@content; 
	}
}
@mixin desktop{
	@media (min-width: $desktop){
		@content; 
	}
}
@mixin extralarge{
	@media (min-width: $extralarge){
		@content; 
	}
}
@mixin telEscritorio{
	@media (min-width: $telefono )and(max-width: $tablet){
		@content; 
	}
	@media (min-width: $desktop){
		@content; 
	}
}
@mixin telTablet{
	@media (max-width: $desktop){
		@content; 
	}
}

@mixin iconos ($icono){		
		@extend .fas;
		&:after{
			content:fa-content($icono) ;
		}
		@content;
}

@mixin grid ($ncolumnas, $espaciado){
	display: grid;
	grid-template-columns: repeat($ncolumnas, 1fr);
	gap:$espaciado;
}
@mixin gridFilas ($nfilas, $espaciadoFilas){
	display: grid;
	grid-template-rows: repeat($nfilas, 1fr);
	gap:$espaciadoFilas;
}

@mixin transiciones ($itemTransicion, $tiempo){
	transition: $itemTransicion $tiempo ease;
}