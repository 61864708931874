html{
	font-size: 62.5%;
	box-sizing: inherit;
	scroll-behavior: smooth;
}
// html{scroll-behavior:smooth}
*::selection{
	background-color: $fucsia;
	color: #fff;
}
*{margin:0;padding:0;box-sizing:border-box;text-decoration:none}



*, *:before, *:after{
	box-sizing: inherit;
}

body{
	font-family: $fuenteEnlace;
	font-size: 1.6rem;
	&.fijar-body{
		overflow: hidden;
	}
}
.titulo-negro{
	font-family: $fuenteLogo !important;
	font-weight: 600;
	font-size: 3.6rem;
	color: $negro;
}
.titulo-difuminado{
	font-family: $fuenteLogo !important;
	text-align: center;	
	font-size: 3.2rem;
	font-weight: 700;
	background: linear-gradient(310deg,#7928ca, #ff0080e3);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
p{
	font-family: $fuenteEnlace;
	text-align: justify;
	font-size: 1.5rem;
	color: $colorParrafo;
	line-height: 1.7;
	width: 75%;
	
}
.max-width{max-width:1700px;margin:auto}
.contenedor{
	max-width: 130rem;
	width: 80%;
	margin: 0 auto;
	padding: 2rem 1rem;
	z-index: 2;
}

a{
	text-decoration: none !important;
	&hover{text-decoration:none !important;}
}
img{
	max-width: 100%;
	object-fit: cover;
}
h1, h2, h3{margin: 0; font-family: $fuentePrincipal;}
h1{
	font-size: 3rem;
	font-weight: $bold;
	font-family: $fuentePrincipal;
	text-align: center;
	@include tablet{
		font-size: 5rem;
	}
}

h2{
	font-size: 3rem;
}
h3{
	font-size: 2.5rem;
}

.infor{
	margin-top: 28rem;
}
.enlance-secureapp{
	margin-top: 0;
	color: $morado;		
	font-size: 1.6rem;
	@include transiciones (text-shadow, 0.5s);
	@include transiciones (font-size, 0.5s);

	@include tablet{
		margin-top: -3rem;
		font-size: 2.3rem;
	}
	
	&:hover{
		text-shadow: 1px 1px $gris;
		font-size: 2rem;
		@include tablet{
			font-size: 2.5rem;
		}
	}
	i{
		padding: 1rem;
		margin-top: 1rem;
	}
}
.onda{
	position: absolute;
	bottom:0;
	width: 100%;
	
}
.contenedor-onda{
	width: 100%;
	margin-top: -1rem;
	position: absolute;
	top:0;
}
.onda-vertical{
	display: none;
	
	@include desktop{
		display: block;
		position: absolute;
   	height: 58.9rem;
   	left: 43%;
	}
}
.bg-gradient-warning, .bg-gradient-dark{
	cursor: pointer;
   padding: 1rem 2rem;
   border-radius: 10px;
   font-family: $fuenteEnlace;
   text-transform: uppercase;
   font-weight:$bold;
   font-size: 1rem;
   background-color: #fff !important;
   border: none;
   color: $negro;
   @include transiciones (all, .3s);
	@include tablet{
		padding: 1rem 3rem;
		font-size: 1.3rem;
	}
   &:hover{
	   padding: 1.2rem 3.2rem;
   }
}
.bg-gradient-dark{
   /* background-image: linear-gradient(to right top, #000000, #110f10, #1b191b, #232226, #2b2c31, #30343d, #343c49, #374555, #384d65, #3a5576, #3b5d87, #3e6598)!important; */
   background: transparent !important;
	color: #fff !important;
	border: none !important;
	&:hover{
		font-size: 1.4rem;
	}
}
.difuminado-black{
	background: rgb(10,10,10);
	background: linear-gradient(218deg, rgba(10,10,10,1) 13%, rgba(136,142,182,1) 80%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.boton-degradado, .boton-black{
	cursor: pointer;
	width: 31%;
	font-size: 1.1rem;
	color: $blanco;
	background-image: linear-gradient(310deg,#7928cab3, #ff0080);
	padding: 1.2rem;
    border: none;
    border-radius: 1.2rem;
    font-family: 'Open Sans';
    text-transform: uppercase;
    font-weight: 700;
	 @include transiciones (all, .5s);
	 &:hover{
		padding: 1.3rem 1.5rem;
	 }
	 @include tablet{
		 font-size:1.5rem;
	 }
	 @include desktop{
		 font-size:2rem;
	 }
}
.boton-black{
	background: linear-gradient(218deg, rgba(10,10,10,1) 13%, rgba(136,142,182,1) 80%) !important;
}
.enlace-difuminado{
	background: linear-gradient(310deg,#7928ca, #ff0080e3);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-family: $fuenteEnlace;
	font-size: 2rem;
}
.box-shadow{
	-webkit-box-shadow: 9px 7px 26px -6px rgba(0,0,0,0.75);
	-moz-box-shadow: 9px 7px 26px -6px rgba(0,0,0,0.75);
	box-shadow: 9px 7px 26px -6px rgba(0,0,0,0.75);
}
.icono-degradado{
	padding:0;
	font-size: 2.5rem;
	background: linear-gradient(310deg,#7928ca, #ff0080);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}