
 .ms-auto {
	margin-left: auto !important;
 }
 .me-auto {
	margin-right: auto !important;
 }
 .p-lg-4 {
	padding: 1.5rem !important;
 }
 .mt-lg-0 {
	margin-top: 0 !important;
 }
 .mt-4 {
	margin-top: 1.5rem !important;
 }
 .pt-7 {
	padding-top: 6rem !important;
 }
 .mb-0 {
	margin-bottom: 0 !important;
 }
 .text-center {
	text-align: center !important;
 }
 .text-white {
	color: #fff !important;
 }
.card {
	position: relative;
	display: flex;
	flex-direction: column;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 0 solid rgba(0, 0, 0, 0.125);
	border-radius: 1rem;
 }
 .card.card-background {
	align-items: center;
 }
 .card.card-background.card-background-mask-primary:before {
	background: rgba(0, 0, 0, 0.2);
 }
 
 .card.card-background.card-background-mask-primary:after {
	background-image: linear-gradient(310deg, #7928CA 0%, #FF0080 100%);
	opacity: .85;
 }
 .tilt {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
 }
 
 .tilt .up {
	-webkit-transform: translateZ(50px) scale(0.7);
	transform: translateZ(50px) scale(0.7) !important;
	transition: all 0.5s;
 }
 .card.card-background .full-background {
	background-position: 50%;
	background-size: cover;
	margin-bottom: 30px;
	width: 100%;
	height: 100%;
	position: absolute;
	border-radius: 1rem;
 }
 .card-body {
	flex: 1 1 auto;
	padding: 1rem 1rem;
 }
 .card .card-body {
	font-family: "Open Sans";
	padding: 1.5rem;
 }

 .card.card-background .card-body {
	color: #fff;
	position: relative;
	z-index: 2;
 }
 
 .card.card-background .card-body .content-center,
 .card.card-background .card-body .content-left {
	min-height: 330px;
	max-width: 450px;
	padding-top: 60px;
	padding-bottom: 60px;
 }
 
 .card.card-background .card-body .content-center {
	text-align: center;
 }
 
 .card.card-background .card-body.body-left {
	width: 90%;
 }
 
 .card.card-background .card-body .author .name span,
 .card.card-background .card-body .author .name .stats {
	color: #fff;
 }
 .card.card-background:after {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	display: block;
	content: "";
	background: rgba(0, 0, 0, 0.4);
	border-radius: 1rem;
 }