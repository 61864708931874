
.navbar{

	position: fixed;
	width: 85%;
	padding: 0;
	font-family: Ubuntu,sans-serif;
	top: 2rem;
	z-index: 999;
	border-radius: 5rem !important;
	margin-left: 8%;	
	/* display:flex;
	flex-direction:row;
	justify-content:center;
	align-items:center; */
	
	@include desktop{
		margin-left: 5%;
		width: 90%;		
	}
	@include extralarge{
		width: 85%;
	}
	.max-width{display:flex;align-items:center;justify-content:space-between;}
	.logo-blanco{
		@include desktop{
			width:24%;
		}
	}
	.logo {
	
		a{	
			cursor: pointer;
			@include transiciones (ease, .5s);
			img{
				/* content: 0; */
				width:2rem  !important;
				display: none;
				
				@include tablet{
					padding-right: 96%;
				}
				@include desktop{
					padding-right: 0 !important;
				}
				
				&:hover{
					width:6.2rem;
				}
			}
		}
	
		
	}
	.menu li{
		display:inline;text-decoration:none;
		a{
			display: inline-block;
			color: #fff;
			font-size: 1.5rem;
			font-family: $fuenteEnlace;
			font-weight: $delgada;
			margin-left: $separacionDos;
			@include transiciones (all, 0.5s);
			&:hover{
				padding-bottom: 3px;
				border-bottom: 1.5px solid #fff;
			}
		}
	}
	

}
.logotipo{
	display: block;
	width: 30%;
	cursor: pointer;
	@include tablet{
		width: 30%;
	}
	@include desktop{
		width: 65%;
	}

}
.logotipo-morado{
	display: none  !important;
}

.navbar.sticky{
	width: 85%;
	margin-left: 5%;	
	padding: .7rem 0; background-color: rgba(255, 255, 255, 0.856);  color:$botonPrincipal; box-shadow: 0px 0px 4px 0px  rgba(0,0,0,0.5);
	
}

.navbar.sticky .logotipo{
	display: none;
}
.navbar.sticky .logotipo-morado{
	display: block !important;
	width: 30%;
	cursor: pointer;
	@include tablet{
		width: 40%;
	}
}
.navbar.sticky .menu {
	@include desktop{
		width: 160%;
	}
}

.navbar.sticky .menu li a{ 
	color: #fff;
	@include desktop{
		font-size: 1.3rem;
		color: $negro;
	}
	@include extralarge{
		font-size: 1.4rem;
		color: $negro;
	}
}
.navbar.sticky .icono-redes{ 
	color: #fff;
	@include desktop{
		color: $negro;
	}
}



.navbar.sticky .menu li a:hover{color:$fucsia; border-bottom: 1.5px solid $fucsia;}

/* Menu btn styling (menu dispositivos moviles) */
.navbar.sticky .menu-btn{
	color: $negro;
}
.menu-btn{
	color:#fff;
	font-size:$separacionDos;
	z-index: 3;
	cursor:pointer;
	display:none;
	@include transiciones (font-size, 1s);	
	&:hover{
		font-size:2.3rem;
	}
}


@media (max-width: 900px){
	.max-width{padding:0 $separacion}
	.navbar.sticky{padding:1rem 0;}
	.navbar .menu{
		 position: fixed;
		 background-image: linear-gradient(310deg,#7928ca, #ff0080);
		z-index: 999;
		 height: 100vh;
		 width: 100%;
		 left: -114%;
		 top: -2rem;
		 text-align: center;
		 padding: 8rem 0;
		 @include transiciones (all, 1s);
	}
	
	.navbar .logo a{font-size:2rem;font-weight:$bold}
	.navbar .menu.active{left:0}
	.navbar{
		.menu li{
			display:block;
			a{
				
				display:inline-block;
				margin:1rem 0;
				color: #fff;
				padding: 0 1rem; 
				font-size:2.5rem;
			}
		}
	} 
	
	.menu-btn{
		display:block;z-index:999;
		i.active:before{content:"\f00d"}
	}
	
	.max-width{padding:0 2.3rem}
}
/* Boton scroll */
.scroll-up-btn{
	position: fixed;
	height: 4.5rem;
	width: 4.2rem;
	background: #000;
	right: 3rem;
	bottom: 1rem;
	text-align: center;
	line-height: 4.5rem;
	color: $blanco;
	z-index: 9999;
	font-size: 3rem;
	border-radius: 50%;
	cursor: pointer;
	opacity: 0;
	pointer-events: none;
	@include transiciones (all, .3s);

	&:hover{
		height: 5rem;
		width: 4.8rem;
	}
}
.scroll-up-btn.show{bottom:30px;opacity:1;pointer-events:auto}
/* Iconos redes */
.icono-redes{
	color: rgba(255, 255, 255, 0.63);
	font-size: 2rem;
	margin-left: 1rem;
	border-radius: 50%;	
}
.icono-facebook, .navbar.sticky .iconos-redes .icono-facebook{
	font-size: 2rem !important;
	@include transiciones(color, 1s);
	&:hover{color: #4267b2 !important;text-decoration:none !important;}
}
.icono-instagram,  .navbar.sticky .iconos-redes .icono-instagram{
	font-size: 2rem !important;
	@include transiciones(color, 1s);
	&:hover{color:#d63e91 !important;text-decoration:none !important;}
}
.icono-link,  .navbar.sticky .iconos-redes .icono-link{
	font-size: 2rem !important;
	@include transiciones(color, 1s);
	&:hover{color:#0e76a8 !important;text-decoration:none !important;}
}
/* Boton scroll */
.scroll-up-btn{
	position: fixed;
	height: 4.5rem;
	width: 4.2rem;
	background: #000;
	right: 3rem;
	bottom: 1rem;
	text-align: center;
	line-height: 4.5rem;
	color: $blanco;
	z-index: 9999;
	font-size: 3rem;
	border-radius: 50%;
	cursor: pointer;
	opacity: 0;
	pointer-events: none;
	@include transiciones (all, .3s);

	&:hover{
		height: 5rem;
		width: 4.8rem;
	}
}
.scroll-up-btn.show{bottom:30px;opacity:1;pointer-events:auto}