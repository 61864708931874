.slideshow ul, .slideshow ol{list-style: none;}
.slideshow{
	width: 100%;
	position: relative;
	

	.waves{
		position: absolute;
		bottom: 9px;
		left: 0;
		right: 0;
		width: 100%;
		height: 16vh;
		margin-bottom: -9px;		
		min-height: 100px;
		max-height: 150px;
		z-index: 2;
		/* background-image: linear-gradient(to right, #fa709a 0%, #fee140 100%); */
	}
}

.slider{
	li{
		img{
			height: 600px;
			width: 100%;
			background-size: cover;
			background-position: center;

			@include desktop{
				height: 600px;
			}
			@include extralarge{
				height:700px; 
			}
		}
		
	}
	.caption{
		padding: 15px 0;
		position: absolute;
		width: 100%;
		height: 100%;
		top:0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		z-index: 1;
		color: $blanco;
		height: 520px;
		h1{
			margin-top: 4rem;
			width: 80%;
			font-size: 2.3rem;
			color: $blanco;
			font-family: $fuenteEnlace;
			text-shadow: 1px 1px rgba(0, 0, 0, 0.514);
			@include tablet{	
				margin-top:0;			
				font-size: 3rem;
			}
			@include desktop{
				margin-top: 3rem;
				font-size: 4rem;
				width: 94%;
			}
			@include extralarge{
				margin-top: -5rem;
				font-size: 5rem;
				width: 94%;
			}
			
		}
		p{
			margin-top: 2rem;
			font-size:1.5rem;
			font-weight: $regular;
			text-align: justify;
			width: 25rem;
			
			@include tablet{
				font-size: 2rem;
				width: 61%;
				text-align: center;
			}
			@include desktop{
				font-size: 2rem;
				width: 100rem;
				text-align: center;
			}
		}
		
		
	}
	
	.caption-dos{		
		background-image: linear-gradient(to right top, #05193769, #2c2b66cc, #68358fbb, #b131abb9, #ff0ab4);
		width:100%;
		height: 90%;
		p{
			color: #fff !important;
			font-family: $fuenteEnlace;
			font-weight: 100;
			line-height: 1.5;
		
		}
	}
	/* .caption-sedial{
		background-image: linear-gradient(120deg, #f6d465 0%, #fd9f85 100%)!important;
	} */
	.caption-servicios{
		background-image: linear-gradient(310deg,#141727c2,#3a416f94) !important;
		img{
			display: none;
			
			@include tablet{
				display: block;
				position: absolute;
				left: 0;
				width: 31%;
				height: 50%;
			}
			@include desktop{
				width: 26%;
    			height: 70%;
			}
			@include extralarge{
				width: 28%;
    			height: 79%;
			}
		}
		.img-servicios-dos{
			display: none;
			@include tablet{
				display: block;
    			position: absolute;
    			width: 29%;
    			height: 51%;
    			top: 34%;
    			left: 72%;
			}
			@include desktop{
				width: 26%;
				height: 62%;
				top: 29%;
				left: 74%;
			}
			@include extralarge{
				width: 22%;
				height: 63%;
				top: 31%;
				left: 76%;
			}
		}
		.enlace-difuminado{
			font-weight: 700;
			margin-top: 6rem;
			@include tablet{
				margin-top: 9rem;
			}
		}
		
	}
	

}


/* animacion waves */
/* Animation for the waves */
.moving-waves>use {
	animation: move-forever 40s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
 }
 
 .moving-waves>use:nth-child(1) {
	animation-delay: -2s;
	animation-duration: 11s;
 }
 
 .moving-waves>use:nth-child(2) {
	animation-delay: -4s;
	animation-duration: 13s;
 }
 
 .moving-waves>use:nth-child(3) {
	animation-delay: -3s;
	animation-duration: 15s;
 }
 
 .moving-waves>use:nth-child(4) {
	animation-delay: -4s;
	animation-duration: 20s;
 }
 
 .moving-waves>use:nth-child(5) {
	animation-delay: -4s;
	animation-duration: 25s;
 }
 
 .moving-waves>use:nth-child(6) {
	animation-delay: -3s;
	animation-duration: 30s;
 }
 
 @keyframes move-forever {
	0% {
	  transform: translate3d(-90px, 0, 0);
	}
 
	100% {
	  transform: translate3d(85px, 0, 0);
	}
 }
 .botones-header{
	 margin-top:2rem;
	 display: flex;
 }
 .boton-proyecto{
	 width: 2rem;
 }