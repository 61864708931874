footer{
	width: 100%;
	background-image: linear-gradient(310deg, #141727 0%, #3A416F 100%);
	padding-top: 3rem !important;
    padding-bottom: 3rem !important;
	position: relative !important;
	height: 45rem;
	@include tablet{
		width: 100%;
		height: 25rem;
	}
	.img-white{
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0.6;
		left: 0;
		position: absolute;
	}
}
.container{
	width: 100%;
    margin-right: auto;
    margin-left: auto;
	text-align: center;
	@include grid(1, 0);
	padding: 0 !important;
	@include tablet{
		@include grid(2, 0);
		width: 100%;
	}
	@include desktop{
		width: 100%;
	}
	@include extralarge{
	width: 100%;
	}
}
.row{
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
}
.menu-footer{

	@include desktop{
		text-align: left;
		flex: 0 0 auto;
		
	}
}
.titulo-menu-foot{
	font-weight: 700;
	text-transform: uppercase;
	margin: 1.5rem 0;
	color: #fff;
	font-size: 5rem;
	font-family: $fuenteLogo;
}
.nav-footer{
	display: flex;
	flex-direction: row;
    flex-wrap: wrap;
    padding-left: 5rem;
    margin-bottom: 0;
    list-style: none;
	margin: 0 0 1.5rem -1rem;
	font-size: 1.3rem;
	li{
		a{
			opacity: 0.8 !important;
			cursor: pointer;display: block;
			padding: 0.5rem 1rem;
			color: #cb0c9f;
			transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
			&:hover{
				font-size: 1.4rem;
				color: $fucsia;
			}
		}
	}
	
	
	@include tablet{
		padding-left: 0;
	}
	@include desktop{
		justify-content: flex-start;
	}
}
.parrafo-copyright{
	width: 100%;
	text-align: center;
	opacity: .8;
	font-size: 1.2rem;
	color: #fff;
}
.contenedor-redes{
	text-align: right;
	margin-top: 3rem;
	@include desktop{
		flex: 0 0 auto;
	}
}
.enlaces-redes{
	text-align: right;
}
.parrafo-redes{
	width: 100%;
	text-align: center!important;
	font-weight: 600;
	color: #fff;
	margin-left: 0%;
	margin-bottom: 3rem;
	line-height: 1.6;
	@include tablet{
		text-align: right!important;
	}
}
.enlace-red{
	text-align: left;
	opacity: .5!important;
	color: #fff!important;
	padding: 1rem!important;
	font-size: 2rem!important;
	i{
		-webkit-font-smoothing: antialiased;
   		display: inline-block;
   		font-style: normal;
   		font-variant: normal;
   		text-rendering: auto;
   		line-height: 1;
	}
}
.icon-red{
	text-align: center!important;
	@include tablet{
		text-align: right!important;
	}
}
.enlaces-politicas{
	margin-top:2rem;
	text-align: center!important;
	@include tablet{
		text-align: right!important;
	}
	a{
		color: #fff;
		font-size: 1.3rem;
		opacity: .7;
	}
}
.slogan-footer{
	color: #000;
	width: 100%;
	text-align: center;
	@include tablet{
		text-align:left;
	}
}