

@include tablet{
	.bg-uno, .bg-cuatro{
		background-color: $principal;
	}
	.bg-dos, .bg-tres{
		background-color: $gris;
	}
}
@include telEscritorio{
	
	.bg-uno, .bg-tres{
		background-color: $principal;
	}
	.bg-dos, .bg-cuatro{
		background-color: $gris;
	}

}