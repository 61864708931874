.caracteristicas{
	font-family: $fuenteEnlace;
	margin: 21rem auto 0 auto;
	@include desktop{
		display: grid;
		grid-template-columns: 70% 30%;
		gap: 2rem;
		margin: -1rem auto 0;
	}
	@include extralarge{
		margin: -12rem auto 0;
	}

	.caracteristicas-items{
		@include tablet{
			display:flex;
			align-items: center;
			justify-content:center;
		}
		div[class^="item-"]{   
			i{				
				padding: 2.5rem 0 !important;
				
			}	
			.titulo-item{
				color: $negro;
				font-size: 2rem;
				font-family: $fuenteLogo;
				font-weight: bold;
				font-weight: 600;
			}	
			p{
				text-align: left;
			}
			
		}
	}
}

.caracteristicas-efectocaja{
	height: 44rem;
	background: url('../../../build/img/telefonos.webp');
	background-position: center;
	background-repeat: none;
	background-size: cover;
	border-radius: 25px;
	.contenedor-animado{
		
		height: 91%;
		border-radius: 25px;
		padding: 1rem;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		color: $blanco;
		background-image: linear-gradient(310deg,#7928ca43, #ff008023);
		z-index: 2;
	

		.icono-animadocaja{
			padding-top: 2rem;
			font-size: 8rem;

		}
		h4{
			padding: 36px;
			font-size: 2.5rem;
			font-weight: 900;
			line-height: 1.5;
			font-family: $fuenteLogo!important;
			font-weight: 600;
			@include tablet{
				font-size: 4rem;
				font-family: $fuenteLogo!important;
			}
			@include desktop{
				font-size: 3rem;
				font-family: $fuenteLogo!important;
			}
		}
		p{
			font-family: $fuenteEnlace;
			padding: 1.5rem 0;
			
		}
	}
}
.btn-animado{
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.103);
	font-weight: bold;
	text-transform: uppercase;
	font-size: 1rem;
	border: 1px solid #fff;
	border-radius: 1.875rem;
	color: #fff;
	padding: 1rem 3rem;
	&:hover{
		background-color: transparent;
		text-decoration: none;
		color: #fff;
	}
}


// Seccion clientes

.clientes{
	margin-top: 5rem;
	@include desktop{
		margin-top: 0;
	}
	.encabezado-clientes{	
		p{
			margin-top: 1rem;
			font-size: 1.8rem;
			text-align: center !important;	
			width: 100% !important;
		}
	}
	.body-clientes{
		margin-top: 5rem;
		text-align: center;
		img{
			width: 13rem;
			margin: 0 1rem;
		}
	}
}
