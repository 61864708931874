.caption-blog{
	background: transparent !important;
}
.card.card-backgroundd {
	align-items: center;
 }
 .card.card-backgroundd.card-backgroundd-mask-primary:before {
	background: rgba(0, 0, 0, 0.2);
 }
 
 .card.card-backgroundd.card-backgroundd-mask-primary:after {
	background-image: linear-gradient(310deg,#141727,#3a416f)!important;
	opacity: .85;
 }
 .contenedor-blog{
	width: 100%;
	/* @include tablet{
		width: 45rem;
	} */
 }

 .item-blog{
	 background: url('../../../build/img/img-telefono-tres.webp') center center no-repeat; 
	 background-size:cover;
	 background-size: cover;
    width: 96%;
    height: 21rem;
	 border-radius: 1.5rem;
	 margin: 1.5rem 0rem;	
	 cursor: pointer;
	 @include transiciones(all, .5s);

	 &:hover{
		width: 97%;
		height: 21.2rem;
		outline: 0px;
		box-shadow: rgb(206, 128, 192) 4px 4px 4px 4px!important;
	}
	
	
		
	 
	/*  @include desktop{
		margin:1.5rem 3rem;
	 } */
	
	 .caption-blog{
		background-image: linear-gradient(310deg,#2151ff85,#21d5fdd9)!important;
		height:100%;
		border-radius: 1.5rem;
	 }
}
 .item-bdos{
	 background: url('../../../build/img/img-informatica.webp') center center no-repeat !important; 
	 .caption-bdos{
		background-image: linear-gradient(310deg,#ed00829f,#fd2121d7);
		height:100%;
		border-radius: 1.5rem;
	 }
}
 .item-btres{
	 background: url('../../../build/img/img-disenoweb.webp') center center no-repeat !important; 
	 .caption-btres{
		background-image: linear-gradient(310deg,#ff21219f,#fdca21d7)!important;
		height:100%;
		border-radius: 1.5rem;
	 }
}
 .item-bcuatro{
	 background: url('../../../build/img/img-tecnologia-prl.webp') center center no-repeat !important; 
	 .caption-bcuatro{
		background-image: linear-gradient(310deg,#e521ffcb,#4c4b4dec)!important;
		height:100%;
		border-radius: 1.5rem;
	 }
}
.icon-white{
	color: #fff;
	margin:.5rem 1rem;
	font-size: 3rem;
}
.titulo-blog{
	margin-top: 1rem;
	margin-left: 0.5rem;
	color: #fff!important;
}
.parrafo-blog{
	color: #fff!important;
	opacity: .7;
	padding: 0rem 0 1rem 1rem;
}
 

/* blog dos */
.titulo-blogd{
	font-size: 4rem; 
	letter-spacing:3px; 
	color: #fff!important;
	font-family: $fuenteLogo;
	margin-left: 5%!important;
	font-weight:700;
}
.parrafo-blog{
	color:#fff;
	margin-left: 5%!important; 
	text-align: left!important;
	width: 100% !important;
}
.blog-index{	
	background: hsla(179, 67%, 66%, 1);

background: linear-gradient(90deg, hsla(179, 67%, 66%, 1) 0%, hsla(238, 74%, 61%, 1) 100%);

background: -moz-linear-gradient(90deg, hsla(179, 67%, 66%, 1) 0%, hsla(238, 74%, 61%, 1) 100%);

background: -webkit-linear-gradient(90deg, hsla(179, 67%, 66%, 1) 0%, hsla(238, 74%, 61%, 1) 100%);
	position: relative;
	height:160rem;
	padding: 0!important;
	width: 100%;
	@include tablet{
		height:122rem;
	}
	@include desktop{
		height:76rem;
	}
}
.inicio-blog{
	position: absolute;
    margin-top: 9%!important;
	margin-left: 3%;
	width: 90%;
	@include grid(1, 0);
	height:0rem;
	
}
.items-blog{
	@include grid(1, 0);
	margin-top: 0;
	margin-left: 0;
	height:30rem;
	@include tablet{
		margin-top: 5rem;
		@include grid(2,0);
	}
	@include desktop{
		margin-left: 0!important;
		@include grid(3,3rem);
	}
}

.blog-card{
	top: 35%;
	margin-top: 2rem;

	height: 22rem;
	width: 90%;

	
	position:relative;
	border-radius: 2rem;
	
	
	transition: .2s ease-out;
	overflow: hidden;
	transform-origin: 50% 0;
	transform: perspective(999px) rotateX(0deg) translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	will-change: transform,box-shadow;
	@include desktop{
		margin-top: 0;
		top: 25%;
		width:100%;
		height:100%;
	}
	&:hover{
		transform: perspective(999px) rotateX(7deg) translate3d(0,-4px,5px);
	}
}
.blog-cardu{
	background: url('../../../build/img/img-calidad-software.webp')center no-repeat;
	background-size: cover;
}
.blog-cardd{
	background: url('../../../build/img/img-aws.webp')center no-repeat;
	background-size: cover;
}
.blog-cardt{
	background: url('../../../build/img/img-tendencias-diseno.webp')center no-repeat;
	background-size: cover;
}
.encabezado-blog{
	position: absolute;
	top:5%;
	@include tablet{
		top: 17%;
	}
	@include extralarge{
		top: 17%;
	}
}
.caption-blogu{
	cursor:pointer;
	border-radius: 20px;
	display: block;
	background-color:rgba(0, 0, 0, 0.5);
	position: absolute;
	width:100%;
	height:100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	color: #fff;   	
	h3{
		padding: 1rem;
		font-size:2rem;
		font-family: $fuenteLogo;
		font-weight:700;
	}
	p{
		font-family: $fuenteEnlace;
		padding: 1rem!important;
		color: #fff!important;
		font-size: 1rem;width:100%;
		margin-left: 10%;
		span{
			font-weight: 600;
			text-transform:uppercase;
		}
	}
}
.redes-blog{
	margin-top: 54rem;
    margin-left: 1%;
	border-radius: 2rem;
	width: 86%!important;
	height: 5%!important;
	@include tablet{
		height: 40rem;
		margin-top: 38%;
		margin-left: 0%;
	}
	@include desktop{
		margin-top: 0;
		margin-left: 0;
		height: 90% !important;	
	}
	border-radius: 2rem;
	width: 90%!important;
	height: 90% !important;	
	transition: .2s ease-out;
	overflow: hidden;
	transform-origin: 50% 0;
	transform: perspective(999px) rotateX(0deg) translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	will-change: transform,box-shadow;
	&:hover{
		transform: perspective(999px) rotateX(7deg) translate3d(0,-4px,5px);
	}
}
.onda-blog{
	height: 5%;
	top: -1rem!important;
	width:100%;
	margin-top: 0!important;
	position: absolute!important;
}

/*Pagina blog */
.caption-blog{
	background: transparent !important;
}
.card.card-backgroundd {
	align-items: center;
 }
 .card.card-backgroundd.card-backgroundd-mask-primary:before {
	background: rgba(0, 0, 0, 0.2);
 }
 
 .card.card-backgroundd.card-backgroundd-mask-primary:after {
	background-image: linear-gradient(310deg,#141727,#3a416f)!important;
	opacity: .85;
 }
 .contenedor-blog{
	width: 100%;
	/* @include tablet{
		width: 45rem;
	} */
 }

 .item-blog{
	 background: url('../../../build/img/img-tendencias-diseno.webp') center center no-repeat; 
	 background-size:cover;
	 background-size: cover;
    width: 95%;
    height: 20rem;
	 border-radius: 1.5rem;
	 margin: 1.5rem 0rem;
	
	 cursor: pointer;
	/*  @include desktop{
		margin:1.5rem 3rem;
	 } */
	
	 .caption-blog{
		background-image: linear-gradient(310deg,#2151ff85,#21d5fdd9)!important;
		height:100%;
		border-radius: 1.5rem;
	 }
}
 .item-bdos{
	 background: url('../../../build/img/img-informatica.webp') center center no-repeat !important; 
	 .caption-bdos{
		background-image: linear-gradient(310deg,#ed00829f,#fd2121d7);
		height:100%;
		border-radius: 1.5rem;
	 }
}
 .item-btres{
	 background: url('../../../build/img/img-disenoweb.webp') center center no-repeat !important; 
	 .caption-btres{
		background-image: linear-gradient(310deg,#ff21219f,#fdca21d7)!important;
		height:100%;
		border-radius: 1.5rem;
	 }
}
 .item-bcuatro{
	 background: url('../../../build/img/img-tecnologia-prl.webp') center center no-repeat !important; 
	 .caption-bcuatro{
		background-image: linear-gradient(310deg,#e521ffcb,#4c4b4dec)!important;
		height:100%;
		border-radius: 1.5rem;
	 }
}
.icon-white{
	color: #fff;
	margin:.5rem 1rem;
	font-size: 3rem;
}
.titulo-blog{
	margin-top: 1rem;
	margin-left: 0.5rem;
	color: #fff!important;
}
.parrafo-blog{
	color: #fff!important;
	opacity: .7;
	padding: 0rem 0 1rem 1rem;
	width: 90% !important;
}
/* Entrada blog */

.botones-entrada{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	.btn-entrada{
		margin: 0 2rem;
	}
}

.entrada{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.contenido-entrada{
		margin-top: 8rem;
		p{
			width:100%;
			text-align: justify;
		}
	}
}
.imagen-entrada{
	border-radius: 2rem;
}