
$fuenteLogo: 'Exo 2', sans-serif;
$fuentePrincipal: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$fuenteEnlace: 'Open Sans', sans-serif;

/* Colores */
$negro: #252f40;
$blanco: #fff;
$gris: #747474;
$colorParrafo: #67748e;
$grisFuente: #252f40;
$principal: hsla(0,0%,100%,.72);
$fucsia: #ff0080;
$morado: #7928ca;
$botonPrincipal: #ff8e01;

$cafe: #994500;

/* Media Queries */
$telefono: 300px;
$tablet: 700px;
$tablet2: 717px;
$desktop: 1000px;
$extralarge: 1500px;

/* Fuentes */
$delgada: 300;
$regular: 400;
$bold:700;
$black: 900;

$fa-font-path:  "../../../node_modules/@fortawesome/fontawesome-free/webfonts";



/* Separaciones */
$separacion: 5rem;
$separacionDos: 2rem;