
@import url('https://fonts.googleapis.com/css2? family = Montserrat: wght @ 400; 700; 900 & display = swap');

/* Principal  */
@import url('https://fonts.googleapis.com/css2?family=Hanalei&family=Montserrat:wght@400;700;900&family=Raleway:wght@400;700;900&display=swap');

@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss'; 


@import 'base/normalize';
@import 'base/variables';
@import 'base/mixins';
@import 'base/globales';
@import 'base/utilidades';



@import 'layoud/styles';
@import 'layoud/header';
@import 'layoud/slider';
@import 'layoud/services';
@import 'layoud/caracteristicas';
@import 'layoud/proyectos';

@import 'layoud/lineup';
@import 'layoud/nosotros';
@import 'layoud/team';
/* @import 'layoud/fundadores'; */
@import 'layoud/blogd';
@import 'layoud/info_prl';
@import 'layoud/instructivos';
@import 'layoud/contacto';
@import 'layoud/footer';




