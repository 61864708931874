.seccion-nosotros{
	padding: 4rem;
	margin-top: 40rem;
	@include tablet{
		margin-top: 0;
	}
	h1{
		color: $botonPrincipal;
		span{
			color: $negro;
			font-size: 3rem;
		}
	}
	p{
		padding: 2rem;
		line-height: 1.5em;
		color: $gris;
		font-size: 2rem;
	}
	a{
		padding: 2rem !important;
	}
}


/* pagina nosotros */

	.caption-nosotros{
	text-align: left !important;
	background-image: linear-gradient(310deg,#284ecabb, #7928cab4);
	width:100%;
	height: 90%!important;
	.titulo-caption-nosotros{
		font-size: 2.5rem!important;
		@include tablet{
			font-size: 6rem!important;
		}
	}
	.parrafo-caption-nosotros{
		font-size: 1.5rem!important;
	}
	
}
.about{
	@include grid(1, 0);
	@include desktop{
		@include grid(2, 0);
	}
	.contenido-about{
		p{
			margin: 2rem auto;
			i{
				padding: 0 1rem!important;
			}
		}
		
	}

}
.img-tecnologia{
	margin: .2rem auto;
	border-radius: 1.5rem;
}
// team
.team{
	padding-top: 12rem!important;
	background-image: linear-gradient(310deg,#7928ca, #ff0080);
	padding-bottom: 3rem!important;
	position: relative!important;
	height:90%;
}
.onda-team{
	top: 0!important;
	width:100%;
	margin-top: -1rem !important;
	position: absolute!important;
}
.contenedor-equipo{
	max-width: 540px;
	width: 95%;
	
	padding-right: var(--bs-gutter-x,1.5rem);
	padding-left: var(--bs-gutter-x,1.5rem);
	margin-right: auto;
	margin-left: auto;
	@include tablet{
		width: 100%;
		max-width: 720px;
	}
	@include desktop{
		max-width: 1140px;
	}
	.titulo-team{
		margin: 2rem auto;
		h1,p{
			font-family: $fuenteEnlace;
			text-align: center;
			color: #fff;
		}
		p{width: 90%!important;}
	}
	.contenedor-team-centro{
    display: flex;
	 flex-direction: row;
	 justify-content: center;


	 
	}
	.contenedor-team{
		--bs-gutter-x: 1.5rem;
		--bs-gutter-y: 0;
		display: flex;
		flex-wrap: wrap;
		margin-top: calc(var(--bs-gutter-y) * -1);
		margin-right: calc(var(--bs-gutter-x) / -2);
		margin-left: calc(var(--bs-gutter-x) / -2);
	}
}

.integrante{
	display: flex;
	flex-direction: column;
	width: 88%;
	 position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: 1rem;
	 padding: 1rem;
	 margin: 1.5rem 0;
	 z-index: 2;
	 @include tablet{
		flex-direction: row;
		width: 95%;
		margin: 1.5rem;
	 }
	 @include desktop{
		width: 43%;
	 }
	 img{
		margin: 0 auto;
		 width: 30%;
		 height:90%;
		 border-radius:1.3rem;
	 }
}
.contenido-integrante{
	p{
		padding: .5rem;
		margin: 0 auto;
		font-size:1.5rem;
	}
}
