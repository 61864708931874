.proyectos{
    margin-top: 0rem;
    display: grid;
    @include tablet{
        margin-top: -2rem;
        grid-template-columns: 50% 40%;
        gap: 0;
    }
    @include desktop{
        margin-top: -17rem;
    }
    .proyectos-contenido{
        @include tablet{
            display: flex;     
            flex-direction:column;   
            justify-content: center;
            align-items: center;
        }
        h1{
            font-size: 3rem;
            padding-left: .5rem;
            @include tablet{
                font-size: 4rem;
            }
            @include desktop{
                font-size: 4.4rem;
            }
        }
        h2{
            font-size: 3rem;
            padding-left: .5rem;
            @include tablet{
                font-size: 4.2rem;
            }
            @include desktop{
                font-size: 5.4rem;
            }
        }
        p{
            font-size: 1.8rem;
            text-align: justify;
            margin: 2rem auto;  
        }
        button{
            margin: -2rem auto 1rem 30%;
            font-size: 1.1rem;
        }
    }
    .proyectos-imagenes{
        display: none;
       @include tablet{
        display: block;
        @include grid (2, 0);
        @include gridFilas (1, 0);
        padding: 0;
        width: 80%;
       .imguno{
           margin-top: 10%;
       }
       }
       @include extralarge{
        padding: 0 5rem;
       }
    }
}
.carousel-imagenes{
    display: block!important;
    @include tablet{
        display: none!important;
    }
}

.imagen{
    position: relative;
    height: 20rem;
    width: 100%;
    background: url('../../../build/img/img-gosmart.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    margin-top: 2rem;
    cursor: pointer;
    @include transiciones(all, 1s);
   &:hover{
    height: 21rem;
    .imagen-caption{
        @include transiciones(all, 1s);
        border-radius: 20px;
        display: block;
        background: linear-gradient(310deg,#7928ca68, #ff00803e);
        position: absolute;
        width:100%;
        height:100%;
    }
   }
    @include tablet{
        height: 14rem;
        width: 90%;
        border-radius: 20px;
        margin-top: 2rem;
    }
    @include desktop{
        height: 20rem;
        width: 90%;
    }
    
}
.imagen-dos{
    height: 20rem;
    width: 100%;
    background: url('../../../build/img/img-secur.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    margin-top: 2rem;
    cursor: pointer;
    @include transiciones(all, .5s);
   
    @include tablet{
        height: 14rem;
        width: 90%;
        border-radius: 20px;
        margin-top: 2rem;
    }
    @include desktop{
        height: 20rem;
        width: 90%;
    }
    
}
.imagen-tres{
    height: 20rem;
    width: 100%;
    background: url('../../../build/img/img-connect.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    margin-top: 2rem;
    cursor: pointer;
    @include transiciones(all, .5s);
   
    @include tablet{
        height: 14rem;
        width: 90%;
        border-radius: 20px;
        margin-top: 2rem;
    }
    @include desktop{
        height: 20rem;
        width: 90%;
    }
  
}
.imagen-cuatro{
    height: 20rem;
    width: 100%;
    background: url('../../../build/img/img-healt.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    margin-top: 2rem;
    cursor: pointer;
    @include transiciones(all, .5s);
    
    @include tablet{
        height: 14rem;
        width: 90%;
    }
    @include desktop{
        height: 20rem;
        width: 90%;
    }

}
.contenedorimgdos, .contenedorimgtres{
   
    @include tablet{
        width: 20rem;
    }
    @include desktop{
        width: 26rem;
    }
    @include extralarge{
        width: 36rem;
    }
}
.contenedorimguno, .contenedorimgdos{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.contenedorimgdos{
    height: 66rem;
}
.contenedorimgtres{
    margin-top: 15rem;
    
    @include tablet{ margin-left: 0;}
    @include desktop{margin-left: 1.5rem;}
}

