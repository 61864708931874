.contenedor-servicios {
  background-color: $principal;
  border: none;
  padding: 0rem;
  border-radius: 2rem;
  -webkit-box-shadow: 9px 7px 26px -6px rgba(0,0,0,0.75);
	-moz-box-shadow: 9px 7px 26px -6px rgba(0,0,0,0.75);
	box-shadow: 9px 7px 26px -6px rgba(0,0,0,0.75);
  max-width: 120rem;
  width: 90%;
  margin: -9rem auto 0px auto;
  position: absolute;
  left: 5%;
  right: 20%;
  z-index: 2;

  @include tablet{
    width: 62%;
    margin: -11rem auto 0;
    left: 17%;
  }
  @include desktop{
    width: 51%;
    margin: -11rem auto 0px;
    left: 22%;
  }
  @include extralarge{
    width: 39%;
    margin: -19rem auto 0;
    left: 17%;
  }
 

  h3{
	  text-align: center;
	  color: $blanco;
  }
}

.seccion-servicios {
  width: 100%;
  margin-top: 2.5rem;
  @include grid(1, 2rem);
  @include desktop {
    @include grid(3, .5rem);
  }
  
}

.text-center{
	display: flex;
	flex-direction:column;
	text-align: center;
	align-items: center;
	margin: 0 auto;
	
	
	h1{
    
    background: linear-gradient(
      310deg
      ,#141727,#3a416f);
   
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     i{
       font-size: 3rem;
     }
	
	}
	.titulo-servicio{
		color: $negro;
		font-size: 1.7rem;
    font-family: $fuenteEnlace;
    font-weight: bold;
    padding: 1rem;
	}
  .parrafo-servicio{
	text-align: center;
    font-family: $fuenteEnlace;
    padding: 1rem 0;
    margin-top: -1.5rem;
    font-size: 1.3rem;
    color: $colorParrafo;
  }
}
.servicio-uno, .servicio-dos{
  position: relative !important;
}
.servicio-dos, .servicio-tres{
  margin: 0 27px 0 0;
}
/* .servicio-uno, .servicio-dos, .servicio-tres{
  width: 33%;
  text-align: center;
} */
hr{
  display: none;
  @include desktop{
    display: block;
    margin: 1rem 0;
    color: #6b6b6b !important;
    border: .1px dashed #6b6b6b;
    opacity: 0.1;
    width: 0;
    position: absolute;
    height: 78%;
    left: 100%;
    right: 0;
    top: 0;
  }
 
}
/* Pagina servicios */

.serviciosprl{
  font-family: $fuenteEnlace;
	margin: 5rem auto 0 auto!important;
	@include desktop{
		display: grid;
		grid-template-columns: 30% 70%!important;
		gap: 5rem!important;
		margin: -1rem auto 0;
	}
	@include extralarge{
		margin: 3rem auto 0!important;
	}
}
.serviciosprl-items{
  margin-left: 5%;
}
.caracteristicas-efectocajad{
	height: 44rem;
	background: url('../../../build/img/img-serviciod.webp')!important;
	background-position: center;
	background-repeat: none;
	background-size: cover;
	border-radius: 25px;
	.contenedor-animado{
		
		height: 91%;
		border-radius: 25px;
		padding: 2rem;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		color: $blanco;
		background-image: linear-gradient(310deg,#7928ca00, #ff008000)!important;
		z-index: 2;
	

		.icono-animadocaja{
			padding-top: 2rem;
			font-size: 8rem;

		}
		h4{
			padding: 36px;
			font-size: 2.5rem;
			font-weight: 900;
			line-height: 1.5;font-family: $fuenteEnlace;
			@include tablet{
				font-size: 4rem;
			}
			@include desktop{
				font-size: 3rem;
			}
		}
		p{
			font-family: $fuenteEnlace;
			padding: 1.5rem 0;
		}
	}
}
.caracteristicas-efectocajat{
	height: 44rem;
	background: url('../../../build/img/img-nosotros.webp')!important;
	background-position: center;
	background-repeat: none;
	background-size: cover;
	border-radius: 25px;
	.contenedor-animado{
		
		height: 91%;
		border-radius: 25px;
		padding: 2rem;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		color: $blanco;
		background-image: linear-gradient(310deg,#0091ff00, #ff008000)!important;
		z-index: 2;
	

		.icono-animadocaja{
			padding-top: 2rem;
			font-size: 8rem;

		}
		h4{
			padding: 36px;
			font-size: 2.5rem;
			font-weight: 900;
			line-height: 1.5;font-family: $fuenteEnlace;
			@include tablet{
				font-size: 4rem;
			}
			@include desktop{
				font-size: 3rem;
			}
		}
		p{
			font-family: $fuenteEnlace;
			padding: 1.5rem 0;
		}
	}
}
.caracteristicas-efectocajac{
	height: 44rem;
	background: url('../../../build/img/img-asesoria.webp')!important;
	background-position: center;
	background-repeat: none;
	border-radius: 25px;
	.contenedor-animado{
		
		height: 91%;
		border-radius: 25px;
		padding: 2rem;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		color: $blanco;
		background-image: linear-gradient(310deg,#0091ff00, #ff008000)!important;
		z-index: 2;
	

		.icono-animadocaja{
			padding-top: 2rem;
			font-size: 8rem;

		}
		h4{
			padding: 36px;
			font-size: 2.5rem;
			font-weight: 900;
			line-height: 1.5;font-family: $fuenteEnlace;
			@include tablet{
				font-size: 4rem;
			}
			@include desktop{
				font-size: 3rem;
			}
		}
		p{
			font-family: $fuenteEnlace;
			padding: 1.5rem 0;
		}
	}
}