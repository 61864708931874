.contacto{	
	margin-top: 5rem;
	margin-bottom: 2rem;
	width: 95%!important;
	@include tablet{
		@include grid(2, 0);
	}
	.imagen-contacto{
		background-image: linear-gradient(310deg,#7928ca, #ff0080);
		border-radius: 2rem;
		margin: 1rem;
		display: flex;
		flex-direction:column;
		align-items:center;
		height: 44rem;		
		position: relative;
		@include tablet{
			height: 100%;
		}
		@include desktop{
			height: 59rem;
		}
		@include extralarge{
			height: 70rem;
		}
		
		.info-contacto{
			display:none;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			@include tablet{
				display:block;
			}
		}
		@include tablet{
			display:block;
		}
	}
	
	.parrafos-contacto{
		position: absolute;
		top: 60%!important;
		left: 10%;
		width: 100%;

		@include grid(1, 0);
		@include tablet{
			top: 65%!important;
			left: 5%;
			width: 100%;	
		}
		@include desktop{
			@include grid(2, 0);
			top: 80%!important;
			left: 5%;
			width: 100%;	
		}
		@include extralarge{
			top: 80%!important;
			left: 10%;
			width: 100%;
	
		}
		.parrafo-contacto{
			text-align: justify;
			color: #fff;
			padding: 0;
			margin-left: 10%;		
		}
	}
	.icono-contacto{
		color: #fff;
	}

	
}
.fondo-contacto{
	width: 100%;
    height: 100%;
    position: absolute;
}
.img-contacto{

	width: 100%;
    height: 100%;
    margin-top: 0;
    margin-left: 3%;

	@include tablet{
		width: 100%;
    	height: 79%;
    	margin-top: 8%;
	}

	@include desktop{
		width: 80%;
		margin-left: 11%;
	}
	@include extralarge{
		width: 80%;
		margin-left: 11%;
		margin-top: -10%;
	}
}
.form-contacto{
	padding: 10%;
	text-align: center;
	font-family: $fuenteEnlace;
	legend{
		color:#67748e;
		margin-top: 1rem;
	}
	textarea{
		height: 7rem !important;
	}
	input, textarea{
		margin-top: 2rem;
		margin-top: 2rem;
		width: 100%;
		padding: 1rem;
		color: #676b70;
		border: 1px solid #d2d6da;
		border-radius: 8px;
		transition: box-shadow .15s ease,border-color .15s ease;
		font-weight: 400;
		line-height: 1.4rem;
		&:focus{
			color: #495057;
			background-color: #fff;
			border-color: #e293d3;
			outline: 0;
			box-shadow: 0 0 0 2px #e9aede;
		}
		@include desktop{
			width: 50%;
		}
	}
	.btn-input-form{
		width: 95% !important;
		margin-top: 3rem !important;
		padding: 1.5rem !important;
		line-height: 1.4rem;
		
		@include desktop{
			width: 50% !important;			
		}
		@include transiciones (all, .5s);
		&:hover{
			padding: 1.7rem 2rem !important;
		}
	}
}